/** @format */

import React from "react";
import { Link, NavLink } from "react-router-dom";

const BookingTab = () => {
  return (
    <>
      <div className="book-section mb-3">
        <div className="container">
          <div className="text-centerr" style={{ textAlign: "center" }}>
            {/* <Link to="/booking" className="btn btn-secondaryy rounded-0 px-5">
              Book Now
            </Link> */}
            <Link
              to="/contact-us"
              className="btn btn-secondaryy rounded-0 px-5"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default BookingTab;
