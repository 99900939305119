/** @format */

import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
// import AutoComplete from '../components/adminComponents/Map/AutoComplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import AutoComplete from "./AutoComplete";

const containerStyle = {
  width: "100%",
  height: "40vh",
};

function SearchedByMap({
  stopArr,
  setStopArr,
  field,
  index,
  searchAddress,
  setStopArrUseApi,
}) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC653TecaJSucmBmqXPr9twuP_jG55NACA",
  });
  const [mapApiLoaded, setMapApiLoaded] = useState(true);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapApi] = useState(null);
  const [places, setPlaces] = useState([]);
  const [center, setCenter] = useState([]);
  const [zoom, setZoom] = useState(15);
  const [address, setAddress] = useState("");
  const [draggable, setDraggable] = useState(true);
  const [lat, setLat] = useState(null);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    if (!searchAddress) {
      setAddress("");
    } else {
      setAddress(searchAddress);
    }
    // console.log(searchAddress,"searchAddress")
  }, [searchAddress]);

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setDraggable(false);
    setCoordinates({
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };
  const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    setDraggable(true);
  };
  function apiHasLoaded(map, maps) {
    setMapApiLoaded(true);
    setMapInstance(map);
    setMapApi(maps);
  }

  const addPlace = (place) => {
    // console.log(place)
    setPlaces([place]);
    setCoordinates({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const place = await geocodeByPlaceId(results[0].place_id);
    const [place2] = await geocodeByPlaceId(results[0].place_id);
    // console.log(results, latLng, value, place, place2 )
    const { long_name: postalCode = "" } =
      place2.address_components.find((c) => c.types.includes("postal_code")) ||
      {};
    // console.log("postalCode",postalCode);
    // for (const component of results[0].address_components){
    //   console.log(component.types[0], component.long_name)
    // }
    let postal_code = results[0].address_components.find((c) =>
      c.types.includes("postal_code")
    );
    let locality = results[0].address_components.find((c) =>
      c.types.includes("locality")
    );
    let administrative_area_level_1 = results[0].address_components.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    // console.log(postal_code, administrative_area_level_1,"aaaaaaaa")
    setStopArr(
      stopArr.map((stop, i) =>
        i === index
          ? {
              ...stop,
              [field]: {
                latitude: latLng.lat,
                longitude: latLng.lng,
                map:
                  "https://www.google.com/maps/search/?api=1&query=" +
                  latLng.lat +
                  "," +
                  latLng.lng,
                address: results[0].formatted_address,
                city: locality.long_name ? locality.long_name : "",
                state: administrative_area_level_1.long_name
                  ? administrative_area_level_1.long_name
                  : "",
                zip: postal_code !== undefined ? postal_code.long_name : "",
              },
            }
          : { ...stop }
      )
    );
    // setStopArrUseApi(true)
    // setAddress(value);
    // addPlace(value)
    // setCoordinates(latLng);
    // setCenter(latLng)
  };

  const _onChange = ({ center, zoom }) => {
    // console.log("center onChange==>", center)
    setCenter(center);
    setZoom(zoom);
  };
  const _onClick = (value) => {
    // console.log(value.latLng.lat)
    // console.log(value.latLng.lat(),"S")
    setCoordinates({
      lat: value.latLng.lat(),
      lng: value.latLng.lng(),
    });
  };

  const _onClick2 = (e) => {
    // console.log(e)
  };

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(coordinates);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  // console.log(coordinates)
  const onLoad2 = (ref) => (this.searchBox = ref);

  const onPlacesChanged = (e) => console.log(e);

  return isLoaded ? (
    <>
      {mapApiLoaded && (
        <div>
          <AutoComplete
            map={mapInstance}
            mapApi={mapApi}
            addplace={addPlace}
            handleSelect={handleSelect}
            coordinates={coordinates}
            address={address}
            setAddress={setAddress}
          />
        </div>
      )}
    </>
  ) : (
    <></>
  );
}

export default SearchedByMap;
