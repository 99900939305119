import React from 'react'
import NavBar from '../../components/landingWebsiteComponents/NavBar'
import Footer from '../../components/landingWebsiteComponents/Footer'
import PrivacyPolicyComponent from '../../components/landingWebsiteComponents/websitePrivacyPolicyComponent/PrivacyPolicyComponent'

const PrivacyPolicyPage = () => {
  return (
    <>
        <NavBar />
        {/* <Banner name="Privacy Policy"/> */}
        <br />
        <PrivacyPolicyComponent />
        <br />
        <Footer />
    </>
  )
}
export default PrivacyPolicyPage