import React, { useEffect } from 'react'
import NavBar from '../../components/landingWebsiteComponents/NavBar'
import Banner from '../../components/landingWebsiteComponents/Banner'
import Footer from '../../components/landingWebsiteComponents/Footer'
import { useDispatch } from 'react-redux'
import AboutandServices from '../../components/landingWebsiteComponents/AboutandServices'
import BookingTab from '../../components/landingWebsiteComponents/BookingTab'
import { tabWhyUs } from '../../action/tabActions'

const WhyUs = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(tabWhyUs())
    }, [])
    return (
        <>
            <NavBar />
            <Banner name="About Us"/>
            <AboutandServices aboutUsPage/>
            <BookingTab />
            <Footer />
        </>
    )
}
export default WhyUs