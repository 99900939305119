/** @format */

import axios from "axios";
import React, { useEffect, useState } from "react";
// import logo2 from "../../assetsgeneral/images/head-logo.png"
// import logo2 from "../../assetsgeneral/images/CONNECT CARE TRANS-04.png"
// import logo2 from "../../assetsgeneral/images/CONNECT_CARE_TRANS-04small.jpg"
import logo2 from "../../assetsgeneral/images/CONNECTCARE_WhiteLogo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import { ENVIRONMENT } from "../../constants";

const Footer = () => {
  const [year, setYear] = useState();
  const getYear = async () => {
    const { data } = await axios.get(
      `https://worldtimeapi.org/api/timezone/Asia/Karachi`
    );
    setYear(data.utc_datetime.substring(0, 4));
  };
  useEffect(() => {
    getYear();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <div className="footer-section">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-4">
              <div className="footer-log mb-3">
                <NavLink to="/">
                  {/* <img src={logo2} id="footerlog" style={{maxWidth: "60% !important"}}/> */}
                  <img src={logo2} id="footerlogo" />
                </NavLink>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="widget">
                <h2>Quick Links</h2>
                <div className="widget_content">
                  <ul>
                    <li>
                      <i className="fa fa-chevron-right" id="foot-icon"></i>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right" id="foot-icon"></i>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right" id="foot-icon"></i>
                      <Link to="/services">Services</Link>
                    </li>
                    {/* <li>
                      <i className="fa fa-chevron-right" id="foot-icon"></i>
                      <Link to="/booking">Booking</Link>
                    </li> */}
                    <li>
                      <i className="fa fa-chevron-right" id="foot-icon"></i>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="widget">
                <h2>Contact Us</h2>
                <div className="widget_content">
                  <ul>
                    <li>
                      <i className="fa fa-phone" id="foot-icon"></i>
                      <Link to="#">(773)340-4373</Link>
                    </li>
                    {/* <li>
                      <i className="fa fa-envelope" id="foot-icon"></i>
                      <Link to="#">Info@connectcaretrans.com</Link>
                    </li> */}
                    <li>
                      <i className="fa fa-globe" id="foot-icon"></i>
                      <Link to="#">www.connectcaretrans.com</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bot">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-10 col-md-12 col-sm-12 d-flex flex-column flex-md-row justify-content-between align-items-center">
              <p className="text-center text-md-left mb-2 mb-md-0">
                © Copyright Connect Care Trans {year}. All Rights Reserved.
                {/* Developed By Iconic Tek */}
              </p>
              <p
                onClick={() => navigate("/privacy-policy")}
                style={{ cursor: "pointer" }}
                className="text-center text-md-right mb-0"
              >
                Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
