/** @format */

import React from "react";
import aboutus from "../../assets/images/About-us.png";

const AboutandServices = ({aboutUsPage}) => {
  return (
    <>
      <div className="conttext-section">
        <div className="container">
          <div className="row align-items-center mb-3">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="connet-text">
                <h2 style={{ color: "#28A2A9" }}>Connect Care Trans</h2>
                {aboutUsPage && <h3>(Non-Emergency Medical Transportation)</h3>}
                <p>
                  {/* We are a small local Chicago NEMT company with a big heart and
                  as a former public chauffeur, our founder has first-hand
                  experience providing exceptional service. We understand the
                  importance of reliability, safety, and professionalism as our
                  core values. */}
                  {aboutUsPage ? `We are a small, locally-owned Chicago Non-Emergency Medical
                  Transportation (NEMT) company. Our team are dedicated and
                  committed to providing exceptional transportation services for
                  individuals needing assistance to access medical appointment.
                  Further, our founders have first-hand extensive experience as
                  public chauffeurs providing reliable, safe and professional
                  service transportation.` : 
                  "Connect Care Trans is an emerging provider of non-emergency transportation services. We are customer-centered by prioritizing the unique needs of each of our clients and offering personalized services that address each of our clients concerns."}
                </p>
                <p>
                  {aboutUsPage ? `When you or your loved ones require transportation to
                  doctors' appointments, hospitals, and even transportation
                  back home, count on us to fulfill those needs with efficiency
                  and compassion. Today, take the initiative to schedule by
                  contacting our reservation team.` :
                  "Furthermore, we believe that no one should miss their medical appointments, and we at Connect Care Trans are committed to simplifying transportation logistics for our clients by ensuring each one of our clients reaches their appointments with ease."}
                </p>
                {aboutUsPage &&
                <p>
                  {` We let our customers speak for us, as one of our customers
                  stated "Connect Care Trans is the best kept secret". Don't
                  just take our word, try us!`}
                </p>
                }
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <img src={aboutus} />
              {/* <div className="btns-border p-5">
                <button className="btn btn-primaryy mb-5 w-100 rounded-0">
                  Drive with us
                </button>
                <button className="btn btn-primaryy w-100 rounded-0">
                  Ride with us
                </button>
              </div> */}
            </div>
          </div>
          <div className="border-bottom-new mb-3"></div>
          <div className="row justify-content-center mb-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="we-offer">
                <h2>Comprehensive NEMT Services</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-3">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="weofferlist">
                <ul>
                  <li>Special Needs Transportation</li>
                  <li>Medical Appointments</li>
                  <li>Dialysis Transportation</li>
                  <li>Rehabilitation Appointments</li>
                  <li>Doctor Appointments</li>
                  <li>Physical Therapy</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="weofferlist">
                <ul>
                  <li>Hospital Discharge</li>
                  {/* <li>Airport Pickups and Drop Off</li> */}
                  <li>Nursing Homes</li>
                  <li>Senior Centers</li>
                  <li>Assisted Living</li>
                  <li>Retirement Home</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-lg-1"></div> */}
      {/* <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="btns-border p-5">
                <button className="btn btn-primary mb-5 w-100 rounded-0">
                  Drive with us
                </button>
                <button className="btn btn-primary w-100 rounded-0">
                  Ride with us
                </button>
              </div>
            </div> */}
      {/* </div> */}
      {/* <div className="border-bottom mb-3"></div> */}
      {/* <div className="row justify-content-center mb-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="we-offer">
                <h2>Comprehensive NEMT Services</h2>
              </div>
            </div>
          </div> */}
      {/* <div className="row justify-content-center mb-3">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="weofferlist">
                <ul>
                  <li>Special Needs Transportation</li>
                  <li>Medical Appointments</li>
                  <li>Dialysis Transportation</li>
                  <li>Rehabilitation Appointments</li>
                  <li>Doctor Appointments</li>
                  <li>Physical Therapy</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="weofferlist">
                <ul>
                  <li>Hospital Discharge</li>
                  <li>Airport Pickups and Drop Off</li>
                  <li>Nursing Homes</li>
                  <li>Senior Centers</li>
                  <li>Assisted Living</li>
                  <li>Retirement Home</li>
                </ul>
              </div>
            </div>
          </div> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
};
export default AboutandServices;

{
  /* <div className="features-section">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="feat-bg">
                    <div className="feat-text">
                        <h3>FEATURES</h3>
                        <p>
                            Connect Care Trans software is a modern system built to 
                            automate all non-emergency medical transportation 
                            processes including routing, scheduling, dispatching, 
                            and billing. It helps manage everyday challenges like 
                            vehicle breakdowns, traffic problems, cancelations, 
                            driver call-offs, will calls, no shows, add-on trips, 
                            on-demand trips, and more. Creating paperless 
                            documentation
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */
}
