import { createStore } from "redux";
// import rootReducer from "./reducer";
import { applyMiddleware, compose, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { changeTheTab } from "./reducer/tabReducer";
import { contactusReducer } from "./reducer/contactusReducer";
import { chatReducer } from "./reducer/chatReducer";

// redux jo lgayi thi pehly
// const store = createStore(rootReducer, +  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
// **********************************************
const reducer = combineReducers({
  changeTheTab: changeTheTab,
  contactus: contactusReducer,
  chats: chatReducer,
});
const middleware = [thunk];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  // initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
