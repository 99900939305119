/** @format */

import axios from "axios";
import {
  GET_CONTACTUS_REQUEST,
  GET_CONTACTUS_SUCCESS,
  GET_CONTACTUS_FAIL,
  GET_CONTACTUS_BY_ID_REQUEST,
  GET_CONTACTUS_BY_ID_SUCCESS,
  GET_CONTACTUS_BY_ID_FAIL,
  CREATE_CONTACTUS_MSG_REQUEST,
  CREATE_CONTACTUS_MSG_SUCCESS,
  CREATE_CONTACTUS_MSG_FAIL,
  SERVERADDRESS,
} from "../constants";
import { notifyFailure, notifySuccess } from "../components/toast";

// SUPER ADMIN CONTACT US
export const getContactUs = (obj) => async (dispatch) => {
  dispatch({
    type: GET_CONTACTUS_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/contactUs/getContactUs`,
      obj
    );
    localStorage.setItem("contactusTable", JSON.stringify(data));
    dispatch({
      type: GET_CONTACTUS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: GET_CONTACTUS_FAIL,
      payload: err,
    });
  }
};

// SUPER ADMIN CONTACT US BY ID
export const getContactUsById = (obj) => async (dispatch) => {
  dispatch({
    type: GET_CONTACTUS_BY_ID_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/contactUs/getContactUsById`,
      obj
    );
    localStorage.setItem("contactusTableModal", JSON.stringify(data));
    dispatch({
      type: GET_CONTACTUS_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: GET_CONTACTUS_BY_ID_FAIL,
      payload: err,
    });
  }
};

// SUPER ADMIN CREATE CONTACTUS MSG
export const createContactUs = (obj, setValues) => async (dispatch) => {
  dispatch({
    type: CREATE_CONTACTUS_MSG_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/contactUs/createContactUs`,
      obj
    );
    dispatch({
      type: CREATE_CONTACTUS_MSG_SUCCESS,
      payload: data,
    });
    notifySuccess("Message sent successfully");
    setValues({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
  } catch (err) {
    // console.log(err)
    notifyFailure(err.response.data.message);
    dispatch({
      type: CREATE_CONTACTUS_MSG_FAIL,
      payload: err,
    });
  }
};
