import React from 'react'

const Banner = ({name }) => {
    return (
        <>
            <div className="bannerslider-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="serhead-text">
                                <h1>{name}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Banner