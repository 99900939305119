/** @format */

import React from "react";
// import logo2 from "../../../assetsgeneral/images/head-logo.png"
// import routelogo from "../../../assetsgeneral/images/gpim.png"
// import dispatchlogo from "../../../assetsgeneral/images/gpimg2.png"
// import schedulelogo from "../../../assetsgeneral/images/gpimg3.png"

const HomeMainComponent = () => {
  return (
    <>
      {/* <!-- slider section start --> */}
      <div className="home-banner-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="benner-text">
                <h1>Connect</h1>
                <h2>Care Trans</h2>
                <p>(Non-Emergency Medical Transportation)</p>
                {/* <h1>&nbsp;</h1>
                <h2>&nbsp;</h2>
                <p>&nbsp;</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeMainComponent;

// <div className="slider-section">
//                 <div className="container">
//                     <div className="row">
//                         <div className="banner-text">
//                             <div className="head-logo">
//                                 <img src={logo2} alt="" id="heaimg" />
//                             </div>
//                             <div className="head-text">
//                                 <p>Modern software for non-emergency medical transportation providers, built to improve scheduling, billing, routing, and dispatching processes.</p>
//                             </div>
//                             <div className="head-btn">
//                                 <button type="button" className="btn btn-light">Learn More</button>
//                                 <button type="button" className="btn btn-warning">Get a Demo</button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {/* <!-- end slider section --> */}
//             <div className="row-section">
//                 <div className="container">
//                     <div className="row justify-content-center">
//                         <div className="col-md-3">
//                             <div className="col-img-bg">
//                                 <img src={routelogo} alt="" id="colimg" />
//                             </div>
//                             <div className="col-text">
//                                 <h4>Routing</h4>
//                                 <p>Limit your costs by cutting down on the number of vehicles and drivers you have on the road.</p>
//                             </div>
//                         </div>
//                         <div className="col-md-3">
//                             <div className="col-img-bg">
//                                 <img src={dispatchlogo} alt="" id="colimg" />
//                             </div>
//                             <div className="col-text">
//                                 <h4>Dispatching</h4>
//                                 <p>Handle your real-time changes like add-on trips, no-shows, cancelations, vehicle breakdowns, and traffic problems.</p>
//                             </div>
//                         </div>
//                         <div className="col-md-3">
//                             <div className="col-img-bg">
//                                 <img src={schedulelogo} alt="" id="colimg" />
//                             </div>
//                             <div className="col-text">
//                                 <h4>Scheduling</h4>
//                                 <p>Ditch your inefficient scheduling process for a fully automated one.</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
