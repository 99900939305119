/** @format */

import React, { useEffect, useState } from "react";
import NavBar from "../../components/landingWebsiteComponents/NavBar";
import Banner from "../../components/landingWebsiteComponents/Banner";
import Footer from "../../components/landingWebsiteComponents/Footer";
import Accordion from "react-bootstrap/Accordion";
import SearchedByMap from "../../components/map/Map/SearchedByMap";
import MapComponent from "../../components/MapComponent";
import axios from "axios";
import { SERVERADDRESS } from "../../constants";
import {
  notifyFailure,
  notifySuccess,
  rideNotification,
} from "../../components/toast";

const BookingOnlinePage = () => {
  const directionsService = new window.google.maps.DirectionsService();
  const [activeKey, setActiveKey] = useState("0");
  const [stopArrUseApi, setStopArrUseApi] = useState(false);

  const handleAccordionToggle = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };
  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    type: "",
    bookingDate: "",
    seats: "1",
    // willCall: false,
    // needAttended: false,
    // numberOfWheelchairs: "1",
    // amSeats: "0",
    // needWheel: false,
    // needAssigned: false,
    pickUpAddress: "",
    pickUpDate: "",
    pickUpTime: "",
    dropOffAddress: "",
    dropOffDate: "",
    dropOffTime: "",
    dateForReservation: "",
    // driverNotes: "",
    // dispatchNotes: "",
    distanceFromBaseLocation: "",
    distanceFromPickToDropOff: "",
    totalMileage: "",
    isExist: false,
    // loadNumber: '',
    // clientIdExternal: '',
    fareAndDistance: "",
    rideHistory: [],
    serviceName: "",
  });
  const [isGuest, setIsGuest] = useState(true);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const [stopArr, setStopArr] = useState([
    {
      address: "",
    },
    {
      address: "",
    },
  ]);
  //   console.log(stopArr);

  //   const distanceCalculate = async () => {
  //     await directionsService
  //       .route({
  //         origin: {
  //           query: "3200 North Elston Avenue Chicago, IL 60618",
  //         },
  //         destination: {
  //           query: "1600 Cottage Grove Avenue, Ford Heights, IL, USA",
  //         },
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //       })
  //       .then((response) => {
  //         console.log("response", response);
  //         // distanceFromBaseLocation = parseFloat(
  //         //   response.routes[0].legs[0].distance.text
  //         //     .replace(/,/g, "")
  //         //     .split(" mi")[0]
  //         // );
  //         // obj.distanceFromBaseLocation = distanceFromBaseLocation;
  //       })
  //       .catch((e) => {
  //         // notifyFailure(e.message);
  //         console.log("Directions request failed due to ", e.message);
  //         // window.alert("Directions request failed due to ", e)
  //       });
  //   };
  //   useEffect(() => {
  //     distanceCalculate();
  //   }, []);

  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (numericValue.length > 0) {
      formattedValue = `(${numericValue.slice(0, 3)}`;
    }
    if (numericValue.length > 3) {
      formattedValue += `)${numericValue.slice(3, 6)}`;
    }
    if (numericValue.length > 6) {
      formattedValue += `-${numericValue.slice(6, 10)}`;
    }
    setValues({
      ...values,
      [fieldName]: formattedValue,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    for (let key of Object.keys(values)) {
      if (values[key] === "") {
        delete values[key];
      }
    }
    values.dateForReservation =
      values?.pickUpDate?.substring(0, 10) + "T00:00:00.000Z";
    values.bookingDate =
      values?.pickUpDate?.substring(0, 10) + "T00:00:00.000Z";
    values.pickUpDate =
      values?.pickUpDate?.substring(0, 10) +
      "T" +
      values.pickUpTime +
      ":00.000Z";
    values.rideHistory = stopArr;
    //   values.dateForReservation =
    //     stopArr[0].date.substring(0, 10) + "T00:00:00.000Z";
    values.pickUpAddress = stopArr[0].address;
    //   values.pickUpDate = stopArr[0].date;
    //   values.pickUpTime = stopArr[0].time;
    values.dropOffAddress = stopArr[stopArr.length - 1].address;
    //   values.dropOffDate = stopArr[stopArr.length - 1].date;
    //   values.dropOffTime = stopArr[stopArr.length - 1].time;
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/reservation/addReservation`,
        { ...values, isExist: !isGuest ? true : false }
      );
      notifySuccess(data);
      setValues({
        name: "",
        firstName: "",
        lastName: "",
        name: "",
        phone: "",
        email: "",
        type: "",
        isExist: true,
        bookingDate: "",
        seats: "1",
        pickUpAddress: "",
        pickUpDate: "",
        pickUpTime: "",
        dropOffAddress: "",
        dropOffDate: "",
        dropOffTime: "",
        dateForReservation: "",
        distanceFromBaseLocation: "",
        distanceFromPickToDropOff: "",
        totalMileage: "",
        fareAndDistance: "",
        rideHistory: [],
        serviceName: "",
      });
      setStopArr([
        {
          address: "",
        },
        {
          address: "",
        },
      ]);
    } catch (err) {
      console.log(err, "err");
      notifyFailure(err.response.data.message);
    }
  };

  //   const calculateFareFrontEnd = async () => {
  //     let newArr = [];
  //     stopArr.forEach((e) => {
  //       newArr.push(e?.address?.address);
  //     });
  //     let obj = {};
  //     // yahan se new logic
  //     let eta = { hour: [], mins: [] };
  //     // yahan takh new logic
  //     await directionsService
  //       .route({
  //         origin: {
  //           query: location.baseLocation.address,
  //         },
  //         destination: {
  //           query: newArr[0],
  //         },
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //       })
  //       .then((response) => {
  //         console.log("response", response);
  //         distanceFromBaseLocation = parseFloat(
  //           response.routes[0].legs[0].distance.text
  //             .replace(/,/g, "")
  //             .split(" mi")[0]
  //         );
  //         obj.distanceFromBaseLocation = distanceFromBaseLocation;
  //       })
  //       .catch((e) => {
  //         notifyFailure(e.message);
  //         console.log("Directions request failed due to ", e.message);
  //         // window.alert("Directions request failed due to ", e)
  //       });
  //     // yahan se new logic
  //     let oldDate = new Date(
  //       stopArr[0].date.substring(0, 10) + "T" + stopArr[0].time + ":00.000Z"
  //     );
  //     // yahan takh new logic
  //     for (let i = 0; i < newArr.length - 1; i++) {
  //       await directionsService
  //         .route({
  //           origin: {
  //             query: newArr[i],
  //           },
  //           destination: {
  //             query: newArr[i + 1],
  //           },
  //           travelMode: window.google.maps.TravelMode.DRIVING,
  //         })
  //         .then((response) => {
  //           console.log("response2", response);
  //           // yahan se new logic
  //           distanceFromPickToDropOff += parseFloat(
  //             response.routes[0].legs[0].distance.text
  //               .replace(/,/g, "")
  //               .split(" mi")[0]
  //           );
  //           // yahan takh new logic
  //           // yahan se new logic
  //           if (response.routes[0].legs[0].duration.text.length > 7) {
  //             const timeString = response.routes[0].legs[0].duration.text;
  //             const hourRegex = /(\d+)\s*hour/;
  //             const minuteRegex = /(\d+)\s*min/;

  //             const hourMatch = hourRegex.exec(timeString);
  //             const minuteMatch = minuteRegex.exec(timeString);

  //             const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
  //             const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;
  //             eta.hour.push(hours);
  //             eta.mins.push(minutes);

  //             let aaa = (hours * 60 + minutes) * 60 * 1000;
  //             console.log(aaa, oldDate, "eta aarha hai");
  //             oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
  //             let oldDateTime = oldDate.toString().split(" ")[4];
  //             let ind = i + 1;
  //             setStopArr(
  //               stopArr.map((stop, index) =>
  //                 index === ind
  //                   ? {
  //                       ...stop,
  //                       date: new Date(oldDate).toISOString().substring(0, 10),
  //                       time: oldDateTime.substring(0, 5),
  //                     }
  //                   : { ...stop }
  //               )
  //             );
  //             console.log(
  //               aaa,
  //               new Date(oldDate).toISOString(),
  //               oldDateTime.substring(0, 5),
  //               "eta aarha hai"
  //             );
  //           } else {
  //             eta.mins.push(
  //               parseInt(
  //                 response.routes[0].legs[0].duration.text.split(" mins")[0]
  //               )
  //             );
  //             // let oldDate = new Date(stopArr[0].date.substring(0, 10) + "T"+stopArr[0].time+":00.000Z").getTime();
  //             let aaa =
  //               parseInt(
  //                 response.routes[0].legs[0].duration.text.split(" mins")[0]
  //               ) *
  //               60 *
  //               1000;
  //             oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
  //             let oldDateTime = oldDate.toString().split(" ")[4];
  //             let ind = i + 1;
  //             setStopArr(
  //               stopArr.map((stop, index) =>
  //                 index === ind
  //                   ? {
  //                       ...stop,
  //                       date: new Date(oldDate).toISOString().substring(0, 10),
  //                       time: oldDateTime.substring(0, 5),
  //                     }
  //                   : { ...stop }
  //               )
  //             );
  //             console.log(
  //               aaa,
  //               oldDate,
  //               oldDateTime.substring(0, 5),
  //               "eta aarha hai"
  //             );
  //           }
  //           console.log("etaeta,", eta);
  //           // yahan takh new logic
  //         })
  //         .catch((e) => {
  //           notifyFailure(e.message);
  //           console.log("Directions request failed due to ", e);
  //           // window.alert("Directions request failed due to ", e)
  //         });
  //     }
  //     // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
  //     distanceFromBaseLocation = parseFloat(distanceFromBaseLocation.toFixed(2));
  //     obj.apiDistanceFromBaseLocation = distanceFromBaseLocation;
  //     distanceFromPickToDropOff = parseFloat(
  //       distanceFromPickToDropOff.toFixed(2)
  //     );
  //     obj.apiDistanceFromPickToDropOff = distanceFromPickToDropOff;
  //       obj.usePrecalculatedMiles = false;
  //     totalMileage = parseFloat(
  //       (distanceFromBaseLocation + distanceFromPickToDropOff).toFixed(2)
  //     );
  //     obj.distanceFromPickToDropOff = distanceFromPickToDropOff;
  //     obj.totalMileage = totalMileage;
  //   };
  return (
    <>
      <NavBar />
      <Banner name="Booking" />
      <div className="conttext-section">
        <div className="container">
          {/* <div className="mb-3">
            <div className="booking-section mb-3">
              <h3 className="text-center mt-4">Schedule Your Trip Now</h3>
            </div>
          </div> */}
          {/* <div className="row justify-content-center ">
            <div
              className="col-md-10 col-sm-12"
              style={{ fontFamily: "inter, sans-serif" }}
            >
              <Accordion activeKey={activeKey} onSelect={handleAccordionToggle}>
                <Accordion.Item
                  eventKey="0"
                  // style={{fontSize: "18px",
                  //     fontWeight: "600",
                  //     lineHeight: "normal",
                  //     color: "rgb(40, 162, 170)"}}
                >
                  <Accordion.Header>
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <span>{"\u00A0"}Step 1: Ride Info</span>
                      <span>
                        {activeKey === "0" ? (
                          <i className="fa fa-caret-up"></i>
                        ) : (
                          <i className="fa fa-caret-down"></i>
                        )}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body row">
                        <div className="col-md-6 mb-3">
                          <form className="row">
                            <div className="col-md-12 mb-3">
                              <label
                                for="selectServiceType"
                                className="bokinglabel mb-2"
                              >
                                Select Service Type
                              </label>
                              <sup className="text-danger f-12">*</sup>
                              <select
                                className="form-control rounded-0 "
                                name="selectServiceType"
                                id="selectServiceType"
                                value={values.type}
                                onChange={handleChange("type")}
                                required
                              >
                                <option value="">Select Type</option>
                                <option value="Ambulatory">Ambulatory</option>
                                <option value="Wheelchair">Wheelchair</option>
                              </select>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label
                                for="pickupDate"
                                className="bokinglabel mb-2"
                              >
                                Pick-Up Date
                              </label>
                              <input
                                className="form-control rounded-0 "
                                type="date"
                                name="pickupDate"
                                id="pickupDate"
                                value={values.pickUpDate}
                                onChange={handleChange("pickUpDate")}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <label
                                for="pickupTime "
                                className="bokinglabel mb-2"
                              >
                                Pick-Up Time
                              </label>
                              <input
                                className="form-control rounded-0 "
                                type="time"
                                name="pickupTime"
                                id="pickupTime"
                                value={values.pickUpTime}
                                onChange={handleChange("pickUpTime")}
                              />
                            </div>
                            {stopArr.length > 0 &&
                              stopArr.map((current, i) => {
                                return (
                                  <>
                                    <div className="col-md-12 mb-3">
                                      <label
                                        for="pickupLocation "
                                        className="bokinglabel mb-2"
                                      >
                                        {i === 0
                                          ? "Pickup Address"
                                          : "Destination Address"}
                                      </label>
                                      <SearchedByMap
                                        stopArr={stopArr}
                                        setStopArr={setStopArr}
                                        field="address"
                                        index={i}
                                        // setStopArrUseApi={setStopArrUseApi}
                                        searchAddress={
                                          stopArr[i].address.address
                                        }
                                      />
                                    </div>
                                  </>
                                );
                              })} */}
          {/* <div className="col-md-12 mb-3">
                              <label
                                for="dropoffLocation "
                                className="bokinglabel mb-2"
                              >
                                Dropoff Location
                              </label>
                              <input
                                className="form-control rounded-0 "
                                type="text"
                                name="dropoffLocation"
                                id="dropoffLocation"
                                placeholder="Your Drop-Off Location"
                              />
                            </div> */}
          {/* <div className="col-md-6 mb-3">
                              <label
                                for="numberOfPassengers "
                                className="bokinglabel mb-2"
                              >
                                Number of Passengers
                              </label>
                              <div className="input-group mb-3 ">
                                <span className="input-group-text rounded-0">
                                  <i className="fa fa-user"></i>
                                </span>
                                <span
                                  className="input-group-text"
                                  id="decreaseBtn"
                                >
                                  <strong>-</strong>
                                </span>
                                <input
                                  type="text"
                                  className="form-control text-center"
                                  name="numberOfPassengers"
                                  id="numberOfPassengers"
                                  //   value="1"
                                  value={values.seats}
                                  onChange={handleChange("seats")}
                                />
                                <span
                                  className="input-group-text rounded-0"
                                  id="increaseBtn"
                                >
                                  <strong>+</strong>
                                </span>
                              </div>
                            </div> */}
          {/* <div className="col-md-6 mb-3">
                              <label
                                for="luggageCount "
                                className="bokinglabel mb-2"
                              >
                                Luggage Count
                              </label>
                              <div className="input-group mb-3 ">
                                <span className="input-group-text rounded-0">
                                  <i className="fa fa-briefcase"></i>
                                </span>
                                <span
                                  className="input-group-text"
                                  id="lugdecreaseBtn"
                                >
                                  <strong>-</strong>
                                </span>
                                <input
                                  type="text"
                                  className="form-control text-center"
                                  name="luggageCount"
                                  id="luggageCount"
                                  value="0"
                                />
                                <span
                                  className="input-group-text rounded-0"
                                  id="lugincreaseBtn"
                                >
                                  <strong>+</strong>
                                </span>
                              </div>
                            </div> */}
          {/* <div className="col-md-6 mb-3">
                              
                              <div>
                                <label
                                  className="bokinglabel"
                                  for="flexSwitchCheckDefault"
                                >
                                  <i className="fa fa-wheelchair"></i>{" "}
                                  Accessible
                                </label>
                              </div>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="flexSwitchCheckDefault"
                                />
                              </div> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="col-md-12">
                              <label
                                for="serviceName"
                                className="bokinglabel mb-2"
                              >
                                Services Name
                              </label>
                              <select
                                className="form-control rounded-0 "
                                name="serviceName"
                                id="serviceName"
                                value={values.serviceName}
                                onChange={handleChange("serviceName")}
                              >
                                <option value="">Select Service Name</option>
                                {values.type === "Ambulatory" && (
                                  <>
                                    <option value="Responsible Party: Standard Vehicle">
                                      Responsible Party: Standard Vehicle
                                    </option>
                                    <option
                                      value="Child Car Seat Included In Vehicle: Booster
                                  Standard Vehicle: Door-Through-Door"
                                    >
                                      Child Car Seat Included In Vehicle:
                                      Booster Standard Vehicle:
                                      Door-Through-Door
                                    </option>
                                    <option
                                      value="Child Car Seat Included In Vehicle: Front/Rea
                                  NON_RIDESHARE_SEDAN"
                                    >
                                      Child Car Seat Included In Vehicle:
                                      Front/Rea NON_RIDESHARE_SEDAN
                                    </option>
                                    <option value="DOOR_THROUGH_DOOR">
                                      DOOR_THROUGH_DOOR
                                    </option>
                                    <option value="DOOR_TO_DOOR">
                                      DOOR_TO_DOOR
                                    </option>
                                    <option value="CAR SEAT">CAR SEAT</option>
                                  </>
                                )}
                                {values.type === "Wheelchair" && (
                                  <>
                                    <option value="WHEELCHAIR_STANDARD_DOOR_THROUGH_DOOR">
                                      WHEELCHAIR_STANDARD_DOOR_THROUGH_DOOR
                                    </option>
                                    <option value="WHEELCHAIR_CHAIR_IN_VEHICLE">
                                      WHEELCHAIR_CHAIR_IN_VEHICLE
                                    </option>
                                  </>
                                )}
                              </select>
                            </div> */}
          {/* </form>
                        </div>
                        <div className="col-md-6"> */}
          {/* <iframe
                            className="w-100"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.580774591111!2d-87.79388052388157!3d41.98780985853801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fc94354c78d17%3A0x8b9c5a102c0b3730!2s5858%20N%20Northwest%20Hwy%20g%2C%20Chicago%2C%20IL%2060631%2C%20USA!5e0!3m2!1sen!2s!4v1713776164566!5m2!1sen!2s"
                            height="400"
                            style={{ border: 0 }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe> */}
          {/* <MapComponent stopArr={stopArr} />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                
                <br />

                
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <span>{"\u00A0"}Step 2: Final Details</span>
                      <span>
                        {activeKey === "0" ? (
                          <i className="fa fa-caret-up"></i>
                        ) : (
                          <i className="fa fa-caret-down"></i>
                        )}
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="accordion-body">
                      <div className="row justify-content-center ">
                        <div className="col-md-12">
                
                          <p>
                            {isGuest
                              ? "Please continue as a new account Or"
                              : "Please continue if you have an account Or"}
                            <button
                              className="btn btn-link"
                              onClick={() => {
                                setIsGuest(!isGuest);
                                setValues({
                                  ...values,
                                  email: "",
                                });
                              }}
                            >
                              {isGuest
                                ? "Already have an account?"
                                : "Create new account"}
                            </button>
                          </p> */}
          {/* <button
                            className="btn btn-link"
                            onClick={() => setIsGuest(!isGuest)}
                          >
                            {isGuest ? 'Already a customer?' : 'Continue as a New Customer'}
                          </button> */}
          {/* </div>

                        <div className="col-md-12">
                          <div className="login-text">
                            <p className="alert rounded-0  alert-info">
                              Basic Details
                            </p>
                          </div>
                          <form
                            className="loginform-text row justify-content-center "
                            onSubmit={handleSubmit}
                          >
                            {isGuest && (
                              <>
                                <div className="col-md-5 mb-3">
                                  <label
                                    htmlFor="fName"
                                    className="bokinglabel mb-2"
                                  >
                                    First Name
                                  </label>
                                  <sup className="text-danger f-12">*</sup>
                                  <input
                                    className="form-control rounded-0"
                                    type="text"
                                    name="fName"
                                    id="fName"
                                    required
                                    value={values.firstName}
                                    onChange={handleChange("firstName")}
                                    placeholder="First Name"
                                  />
                                </div>
                                <div className="col-md-5 mb-3">
                                  <label
                                    htmlFor="lName"
                                    className="bokinglabel mb-2"
                                  >
                                    Last Name
                                  </label>
                                  <sup className="text-danger f-12">*</sup>
                                  <input
                                    className="form-control rounded-0"
                                    type="text"
                                    name="lName"
                                    id="lName"
                                    required
                                    value={values.lastName}
                                    onChange={handleChange("lastName")}
                                    placeholder="Last Name"
                                  />
                                </div>
                                <div className="col-md-5 mb-3">
                                  <label
                                    htmlFor="phoneNumber"
                                    className="bokinglabel mb-2"
                                  >
                                    Phone Number
                                  </label>
                                  <sup className="text-danger f-12">*</sup>
                                  <input
                                    className="form-control rounded-0"
                                    type="tel"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    required
                                    value={values.phone}
                                    // onChange={handleChange('phone')}
                                    onChange={handlePhone("phone")}
                                    placeholder="(123)456-7890"
                                  />
                                </div>
                                <div className="col-md-5">
                                  <label
                                    htmlFor="emailAddress"
                                    className="bokinglabel mb-2"
                                  >
                                    Email Address
                                  </label>
                                  <sup className="text-danger f-12">*</sup>
                                  <input
                                    className="form-control rounded-0"
                                    type="email"
                                    name="emailAddress"
                                    id="emailAddress"
                                    required
                                    value={values.email}
                                    onChange={handleChange("email")}
                                    placeholder="Email"
                                  />
                                </div>
                              </>
                              // ) :
                              // <div className="col-md-5">
                              //   <label htmlFor="emailAddress" className="bokinglabel mb-2">
                              //     Email Address
                              //   </label>
                              //   <sup className="text-danger f-12">*</sup>
                              //   <input
                              //     className="form-control rounded-0"
                              //     type="email"
                              //     name="emailAddress"
                              //     id="emailAddress"
                              //     required
                              //     value={values.email}
                              //     onChange={handleChange('email')}
                              //   />
                              // </div>
                            )}

                            {!isGuest && (
                              <div className="col-md-5">
                                <label
                                  htmlFor="emailAddress"
                                  className="bokinglabel mb-2"
                                >
                                  Email Address
                                </label>
                                <sup className="text-danger f-12">*</sup>
                                <input
                                  className="form-control rounded-0"
                                  type="email"
                                  name="emailAddress"
                                  id="emailAddress"
                                  required
                                  value={values.email}
                                  onChange={handleChange("email")}
                                  placeholder="Email"
                                />
                              </div>
                            )}
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="register">
                                <br />
                                <button
                                  className="btn btn-outline-primary w-100 rounded-0 "
                                  type="submit"
                                  style={{ margin: "5px" }}
                                >
                                  {" "}
                                  Submit{" "}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div> */}
          <div className="d-flex justify-content-center align-items-center">
            <div className="serimg">
              <h2>Coming Soon</h2>
              {/* <img
                  src={underConstruction}
                  style={{ height: "500px", width: "auto" }}
                  alt="Under Construction"
                /> */}
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default BookingOnlinePage;

{
  /* <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Step 1: Ride Info
        </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div className="accordion-body row">
            <div className="col-md-6 mb-3">
                <form action="#" className="row">
                    <div className="col-md-12 mb-3">
                        <label for="selectServiceType" className="bokinglabel mb-2">Select Service Type</label>
                        <select className="form-control rounded-0 " name="selectServiceType" id="selectServiceType">
                            <option value="1">Standard</option>
                            <option value="2">Ambulatory</option>
                            <option value="3">Wheelchair</option>
                        </select>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label for="pickupDate" className="bokinglabel mb-2">Pick-Up Date</label>
                        <input className="form-control rounded-0 " type="date" name="pickupDate" id="pickupDate" />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label for="pickupTime " className="bokinglabel mb-2">Pick-Up Time</label>
                        <input className="form-control rounded-0 " type="time" name="pickupTime" id="pickupTime" />
                    </div>
                    <div className="col-md-12 mb-3">
                        <label for="pickupLocation " className="bokinglabel mb-2">Pick-Up Location</label>
                        <input className="form-control rounded-0 " type="text" name="pickupLocation" id="pickupLocation" placeholder="Your Drop-Off Location" />
                    </div>
                    <div className="col-md-12 mb-3">
                        <label for="dropoffLocation " className="bokinglabel mb-2">Pick-Up Location</label>
                        <input className="form-control rounded-0 " type="text" name="dropoffLocation" id="dropoffLocation" placeholder="Your Drop-Off Location" />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label for="numberOfPassengers " className="bokinglabel mb-2">Number of Passengers</label>
                        <div className="input-group mb-3 ">
                            <span className="input-group-text rounded-0"><i className="fa fa-user"></i></span>
                            <span className="input-group-text" id="decreaseBtn"><strong>-</strong></span>
                            <input type="text" className="form-control text-center" name="numberOfPassengers" id="numberOfPassengers" value="1" />
                            <span className="input-group-text rounded-0" id="increaseBtn"><strong>+</strong></span>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label for="luggageCount " className="bokinglabel mb-2">Luggage Count</label>
                        <div className="input-group mb-3 ">
                            <span className="input-group-text rounded-0"><i className="fa fa-briefcase"></i></span>
                            <span className="input-group-text" id="lugdecreaseBtn"><strong>-</strong></span>
                            <input type="text" className="form-control text-center" name="luggageCount" id="luggageCount" value="0" />
                            <span className="input-group-text rounded-0" id="lugincreaseBtn"><strong>+</strong></span>
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="d-flex gap-2">
                            <div>
                                <label className="bokinglabel" for="flexSwitchCheckDefault"><i className="fa fa-wheelchair"></i> Accessible</label>
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label for="serviceName" className="bokinglabel mb-2">Services Name</label>
                        <select className="form-control rounded-0 " name="serviceName" id="serviceName">
                            <option value="1">Select Service Name</option>
                            <option value="2">Responsible Party: Standard Vehicle</option>
                            <option value="3">Child Car Seat Included In Vehicle: Booster Standard Vehicle: Door-Through-Door</option>
                            <option value="4">Child Car Seat Included In Vehicle: Front/Rea NON_RIDESHARE_SEDAN</option>
                            <option value="5">DOOR_THROUGH_DOOR</option>
                            <option value="6">DOOR_TO_DOOR</option>
                            <option value="7">CAR SEAT</option>
                        </select>
                    </div>
                </form> 
            </div>
        
        </div>
    </div>
</div> */
}

// <div className="accordion" id="accordionExample">
//     <div className="accordion-item">
//         <div className="accordion-body">
//             <div className="row justify-content-center ">
//                 <div className="col-md-12">
//                     <p>Please continue as guest to book your reservation.</p>
//                 </div>

//                 <div className="col-md-12">
//                     <div className="login-text">
//                         <p className="alert rounded-0  alert-info">
//                             Basic Details
//                         </p>
//                     </div>
//                     <form className="loginform-text row justify-content-center ">
//                         <div className="col-md-6 mb-3">
//                             <label for="fName" className="bokinglabel mb-2">First Name</label>
//                             <input className="form-control rounded-0 " type="text" name="fName" id="fName" />
//                         </div>
//                         <div className="col-md-6 mb-3">
//                             <label for="lName" className="bokinglabel mb-2">Last Name</label>
//                             <input className="form-control rounded-0 " type="text" name="lName" id="lName" />
//                         </div>
//                         <div className="col-md-12 mb-3">
//                             <label for="phoneNumber" className="bokinglabel mb-2">Phone Number</label>
//                             <input className="form-control rounded-0 " type="tel" name="phoneNumber" id="phoneNumber" />
//                         </div>
//                         <div className="col-md-12 mb-3">
//                             <label for="emailAddress" className="bokinglabel mb-2">Email Address</label>
//                             <input className="form-control rounded-0 " type="email" name="emailAddress" id="emailAddress" />
//                         </div>
//                         <div className="col-lg-4 col-md-6 col-sm-12">
//                             <div className="register">
//                                 <button className="btn btn-outline-primary w-100 rounded-0 "> Submit </button>
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
