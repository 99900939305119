export const tabBaseLocation = () => {
    return {
      type: "TAB_BASELOCATION",
    };
  };
  
  export const tabAddServices = () => {
    return {
      type: "TAB_ADDSERVICES",
    };
  };
  export const tabInvoicing = () => {
    return {
      type: "TAB_INVOICING",
    };
  };
  export const tabDriverPay = () => {
    return {
      type: "TAB_DRIVERPAY",
    };
  };
  export const tabAmbulatory = () => {
    return {
      type: "TAB_AMBULATORY",
    };
  };
  
  export const tabWheelchair = () => {
    return {
      type: "TAB_WHEELCHAIR",
    };
  };
  
  export const tabStretcher = () => {
    return {
      type: "TAB_STRETCHER",
    };
  };
  
  export const tabFareList = () => {
    return {
      type: "TAB_FARELIST",
    };
  };
  
  export const tabBasicReportRow = () => {
    return {
      type: "TAB_BASIC_REPORT_ROW",
    };
  };
  export const tabReportSearch = () => {
    return {
      type: "TAB_REPORT_SEARCH",
    };
  };
  
  export const tabOrdersSearch = () => {
    return {
      type: "TAB_ORDERS_SEARCH",
    };
  };
  export const tabUnassignedDispatching = () => {
    return {
      type: "TAB_UNASSIGNED_DISPATCHING",
    };
  };
  export const tabPendingDispatching = () => {
    return {
      type: "TAB_PENDING_DISPATCHING",
    };
  };
  export const tabAssignedDispatching = () => {
    return {
      type: "TAB_ASSIGNED_DISPATCHING",
    };
  };
  
  export const tabCalculatorReportRow = () => {
    return {
      type: "TAB_CALCULATOR_REPORT_ROW",
    };
  };
  
  export const tabDriverRouteTripDetail = () => {
    return {
      type: "TAB_DRIVER_ROUTE_TRIP",
    };
  };
  
  export const tabRouteDistanceReport = () => {
    return {
      type: "TAB_ROUTE_DISTANCE_REPORT",
    };
  };
  
  export const tabFullReport = () => {
    return {
      type: "TAB_FULL_REPORT",
    };
  };
  export const tabAideReport = () => {
    return {
      type: "TAB_AIDE_REPORT",
    };
  };
  export const tabWithoutAideReport = () => {
    return {
      type: "TAB_WITHOUT_AIDE_REPORT",
    };
  };
  
  // general website tab actions
  export const tabHome = () => {
    return {
      type: "TAB_HOME",
    };
  };
  export const tabWhyUs = () => {
    return {
      type: "TAB_WHYUS",
    };
  };
  export const tabServices = () => {
    return {
      type: "TAB_SERVICES",
    };
  };
  export const tabFaq = () => {
    return {
      type: "TAB_FAQPAGE",
    };
  };
  export const tabBooking = () => {
    return {
      type: "TAB_BOOKING",
    };
  };
  export const tabContactUs = () => {
    return {
      type: "TAB_CONTACTUS",
    };
  };
  export const tabSignIn = () => {
    return {
      type: "TAB_SIGNIN",
    };
  };
  